<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <custom-loading
      :loading="loading_usuarios"
      color="greyTxt"
      opacity=".4"
    />
    <div
      class="ml-2 font-weight-medium text-h5 black--text pt-4 mb-4 d-flex align-center"
    >
      Proveedores
    </div>

    <v-row>
      <v-col
        cols="24"
        md="12"
      >
        <v-card
          elevation="2"
        >
          <!-- Tabla de Pedidos -->
          <generic-table
            :loading="loading_usuarios"
            :headers="headers_usuarios"
            :items="usuarios"
            height="unset"
            :options="{
              'itemsPerPage':10
            }"
            :actions="actions_usuarios"
            @clearPass="clearPass"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  export default {
    name: 'DashboardView',
    data () {
      return {
        actions_usuarios: [
          {
            icon: 'view-grid-outline',
            title: 'Blanquear Contraseña',
            color: 'sendBtn',
            emit: 'clearPass',
          },
        ],
      }
    },

    computed: {
      usuarios: get('usuarios/items'),
      headers_usuarios: get('usuarios/headers'),
      loading_usuarios: get('usuarios/loading'),
      count_usuarios: get('usuarios/count'),
    },
    created () {
      this.initialize()
    },
    methods: {
      getUsuarios: call('usuarios/getData'),
      initialize () {
        this.getUsuarios()
      },
      clearUser: call('usuarios/clearPass'),

      clearPass (send) {
        this.clearUser({ password: send.cuit, cuit: send.cuit })
      },
    },
  }
</script>
